import { template as template_220fef77358c4e45a9d1117fdd1f485b } from "@ember/template-compiler";
import Component from "@glimmer/component";
export default class MessagesSecondaryNav extends Component {
    get messagesNav() {
        return document.getElementById("user-navigation-secondary__horizontal-nav");
    }
    static{
        template_220fef77358c4e45a9d1117fdd1f485b(`
    {{#in-element this.messagesNav}}
      {{yield}}
    {{/in-element}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
