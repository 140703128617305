import { template as template_5c947e4dee254d52b5d81bd2c210835a } from "@ember/template-compiler";
const FKLabel = template_5c947e4dee254d52b5d81bd2c210835a(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
