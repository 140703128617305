import { template as template_861f711812cc425da88299849c2f32fd } from "@ember/template-compiler";
const FKControlMenuContainer = template_861f711812cc425da88299849c2f32fd(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
