import { template as template_6eac281f2fc44f9885543a1e792d3094 } from "@ember/template-compiler";
const FKText = template_6eac281f2fc44f9885543a1e792d3094(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
